import React, { FC, useEffect, useState } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { isBrowser } from '@agria/utils';
import { Container } from '../../container/Container';
import { LinkTo } from '../../linkTo/LinkTo';
import { Contact } from '../../icon';
import { Typography } from '../../typography/Typography';
import { Logo } from '../../svg';
import { LogoSmall } from '../../svg/svgs/LogoSmall';

// Interface

export interface SimpleHeaderProps {
  companyName?: string;
  contactNumber?: string;
  logoLink?: string;
}

// Styles

const StyledSimpleHeader = styled.header`
  ${({ theme: { space } }) => css`
    padding: ${space.fluidSmall} 0;
  `};
`;

const StyledContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledLogo = styled(LinkTo)`
  ${({ theme: { space } }) => css`
    margin-right: ${space.medium};
  `}
`;

const StyledLogoMobile = styled.span`
  ${({ theme: { media } }) => css`
    @media (min-width: ${media.medium}) {
      display: none;
    }
  `};
`;

const StyledLogoDesktop = styled.span`
  ${({ theme: { maxMedia } }) => css`
    @media (max-width: ${maxMedia.medium}) {
      display: none;
    }
  `};
`;

const StyledContact = styled.div`
  ${({ theme: { space, maxMedia } }) => css`
    display: flex;
    align-items: center;
    gap: ${space.xxSmall};
    @media (max-width: ${maxMedia.medium}) {
      display: none;
    }
  `};
`;

const StyledContactNumberMobile = styled.a`
  ${({ theme: { media } }) => css`
    border: none;
    padding: 0;
    @media (min-width: ${media.medium}) {
      display: none;
    }
  `};
`;

export const SimpleHeader: FC<SimpleHeaderProps> = ({
  companyName,
  contactNumber,
  logoLink = '/',
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(true);
  // Get the current date/time in UTC
  useEffect(() => {
    if (isBrowser) {
      const nowUTC = new Date(new Date().toUTCString());
      // Note: UK is typically UTC or UTC+1 depending on Daylight Saving Time
      // Adjust for UK time (GMT or BST as applicable)
      const isDaylightSavingTime = nowUTC.getTimezoneOffset() < 0;
      const UKHour = nowUTC.getHours() + (isDaylightSavingTime ? 1 : 0);

      // Get the current day in UK time (0 - Sunday, 6 - Saturday)
      const UKDay = nowUTC.getDay();

      // Define call center operating hours (9 AM to 6 PM, Monday to Friday)
      const isOpenState =
        UKDay >= 1 &&
        UKDay <= 5 && // Monday to Friday
        UKHour >= 9 &&
        UKHour < 18; // 9 AM to 6 PM UK time
      setIsOpen(isOpenState);
    }
  }, []);

  return (
    <StyledSimpleHeader>
      <StyledContainer>
        <StyledLogo to={logoLink} title={companyName} state={{ reset: true }}>
          <StyledLogoMobile>
            <LogoSmall />
          </StyledLogoMobile>
          <StyledLogoDesktop>
            <Logo />
          </StyledLogoDesktop>
        </StyledLogo>
        {isOpen && (
          <>
            <StyledContactNumberMobile
              aria-label="Call us"
              href={`tel:${contactNumber?.replace(/\s/g, '')}`}
            >
              <Contact />
            </StyledContactNumberMobile>
            <StyledContact>
              <Contact color="brandOne100" />
              <Typography variant="bodyMediumBold">
                Call us on{' '}
                <a href={`tel:${contactNumber?.replace(/\s/g, '')}`}>
                  {contactNumber}
                </a>
              </Typography>
            </StyledContact>
          </>
        )}
      </StyledContainer>
    </StyledSimpleHeader>
  );
};

export default SimpleHeader;
